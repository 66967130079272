/* eslint-disable no-return-await */
/**
 * @module:Vuex Boiler Plate
 * @name:_Rohan wijesunda
 * @author:_@bisevo
 */
// eslint-disable-next-line import/extensions, import/no-unresolved
import { tokenRequest } from '@core/auth/interceptors/inteceptors'
// eslint-disable-next-line import/extensions, import/no-unresolved
import { DASHBOARD } from '@core/services/dashboard'

/**
 * State
 */
const state = {
  isLoading: false,
  allCompanyOverview: [],
  allCurrentGames: [],
  gameOverview: {},
}

/**
 * getters
 */
const getters = {
  isLoading: state => state.isLoading,
  getCompanyOverview: state => state.allCompanyOverview,
  getGameOverview: state => state.gameOverview,
  getCurrentGames: state => state.allCurrentGames,
}
/**
 * mutations
 */
const mutations = {
  SET_IS_LOADING: (state, data) => {
    state.isLoading = data
  },
  SET_ALL_COMPANY_OVERVIEW: (state, data) => {
    state.allCompanyOverview = data
  },
  SET_ALL_GAME_OVERVIEW: (state, data) => {
    state.gameOverview = data
  },
  SET_ALL_CURRENT_GAMES: (state, data) => {
    state.allCurrentGames = data
  },
}
/**
 * actions
 */
const actions = {

  /**
   * get Company Overview
   * @returns {Promise<unknown>}
   */
  async fetchCompanyOverview({ commit }) {
    commit('SET_IS_LOADING', true)
    return await new Promise((resolve, reject) => {
      tokenRequest()
        .get(DASHBOARD.GET_COMPANY_OVERVIEW)
        .then(response => {
          commit('SET_IS_LOADING', false)
          commit('SET_ALL_COMPANY_OVERVIEW', response.data.data)
          resolve(response.data.data)
        })
        .catch(error => {
          commit('SET_IS_LOADING', false)
          reject(error)
        })
    })
  },
  /**
   * get Current Games
   * @returns {Promise<unknown>}
   */
  async fetchCurrentGames({ commit }) {
    commit('SET_IS_LOADING', true)
    return await new Promise((resolve, reject) => {
      tokenRequest()
        .get(DASHBOARD.GET_CURRENT_GAMES)
        .then(response => {
          commit('SET_IS_LOADING', false)
          commit('SET_ALL_CURRENT_GAMES', response.data.data)
          resolve(response.data.data)
        })
        .catch(error => {
          commit('SET_IS_LOADING', false)
          reject(error)
        })
    })
  },

  /**
   * get Game Overview
   * @returns {Promise<unknown>}
   */
  async fetchGamesOverview({ commit }) {
    commit('SET_IS_LOADING', true)
    return await new Promise((resolve, reject) => {
      tokenRequest()
        .get(DASHBOARD.GET_GAMES_OVERVIEW)
        .then(response => {
          commit('SET_IS_LOADING', false)
          commit('SET_ALL_GAME_OVERVIEW', response.data.data)
          resolve(response.data.data)
        })
        .catch(error => {
          commit('SET_IS_LOADING', false)
          reject(error)
        })
    })
  },

}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}
