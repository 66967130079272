import jwtDefaultConfig from './jwtDefaultConfig'

export default class JwtService {
  // Will be used by this service for making API calls
  axiosIns = null

  // jwtConfig <= Will be used by this service
  jwtConfig = { ...jwtDefaultConfig }

  // For Refreshing Token
  isAlreadyFetchingAccessToken = false

  // For Refreshing Token
  subscribers = []

  constructor(axiosIns, jwtOverrideConfig) {
    this.axiosIns = axiosIns
    this.jwtConfig = { ...this.jwtConfig, ...jwtOverrideConfig }

    // Request Interceptor
    this.axiosIns.interceptors.request.use(
      config => {
        // Get token from localStorage
        const accessToken = this.getToken()

        // If token is present add it to request's Authorization Header
        if (accessToken) {
          // eslint-disable-next-line no-param-reassign
          config.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
        }
        return config
      },
      error => Promise.reject(error),
    )

    // Add request/response interceptor
    this.axiosIns.interceptors.response.use(
      response => response,
      error => {
        // const { config, response: { status } } = error
        const { response } = error
        // const originalRequest = config
        if (response.status === 403) {
          this.removeUser()
          // eslint-disable-next-line no-return-assign
          window.location = '/login'
        }
        if (response && response.status === 401) {
          if (!this.isAlreadyFetchingAccessToken) {
            this.isAlreadyFetchingAccessToken = true
            this.refreshToken().then(r => {
              this.isAlreadyFetchingAccessToken = false
              // Update accessToken in localStorage
              this.setToken(r.data.accessToken)
              this.setRefreshToken(r.data.refreshToken)
              this.onAccessTokenFetched(r.data.accessToken)
            })
          } else {
            return response
          }
        }
        return Promise.reject(error)
      },
    )
  }

  onAccessTokenFetched(accessToken) {
    this.subscribers = this.subscribers.filter(callback => callback(accessToken))
  }

  addSubscriber(callback) {
    this.subscribers.push(callback)
  }

  getToken() {
    return localStorage.getItem(this.jwtConfig.tokenKey)
  }

  getRefreshToken() {
    return localStorage.getItem(this.jwtConfig.refreshTokenKey)
  }

  setToken(value) {
    localStorage.setItem(this.jwtConfig.tokenKey, value)
  }

  removeToken() {
    localStorage.removeItem(this.jwtConfig.tokenKey)
    localStorage.removeItem(this.jwtConfig.refreshTokenKey)
  }

  getUser() {
    return JSON.parse(localStorage.getItem(this.jwtConfig.userKey))
  }

  setUser(value) {
    localStorage.setItem(this.jwtConfig.userKey, JSON.stringify(value))
  }

  getUserName() {
    return JSON.parse(localStorage.getItem(this.jwtConfig.userNameKey))
  }

  setUserName(value) {
    localStorage.setItem(this.jwtConfig.userNameKey, JSON.stringify(value))
  }

  removeUserName() {
    localStorage.removeItem(this.jwtConfig.userNameKey)
  }

  getRememberMe() {
    return JSON.parse(localStorage.getItem(this.jwtConfig.rememberMeKey))
  }

  setRememberMe(value) {
    localStorage.setItem(this.jwtConfig.rememberMeKey, JSON.stringify(value))
  }

  removeRememberMe() {
    localStorage.removeItem(this.jwtConfig.rememberMeKey)
  }

  getPassword() {
    return JSON.parse(localStorage.getItem(this.jwtConfig.passwodKey))
  }

  setPassword(value) {
    localStorage.setItem(this.jwtConfig.passwodKey, JSON.stringify(value))
  }

  removePassword() {
    localStorage.removeItem(this.jwtConfig.passwodKey)
  }

  removeUser() {
    localStorage.removeItem(this.jwtConfig.userKey)
  }

  getContent() {
    return JSON.parse(localStorage.getItem(this.jwtConfig.contentKey))
  }

  setContent(value) {
    localStorage.setItem(this.jwtConfig.contentKey, JSON.stringify(value))
  }

  removeContent() {
    localStorage.removeItem(this.jwtConfig.contentKey)
  }

  // eslint-disable-next-line class-methods-use-this
  setLocalStorage(key, value) {
    localStorage.setItem(key, JSON.stringify(value))
  }

  // eslint-disable-next-line class-methods-use-this
  getLocalStorage(key) {
    return JSON.parse(localStorage.getItem(key))
  }

  setRefreshToken(value) {
    localStorage.setItem(this.jwtConfig.refreshTokenKey, value)
  }

  register(...args) {
    return this.axiosIns.post(this.jwtConfig.registerEndpoint, ...args)
  }

  refreshToken() {
    return this.axiosIns.post(this.jwtConfig.refreshEndpoint, {
      refreshToken: this.getRefreshToken(),
    })
  }
}
