import mock from '@/@fake-db/mock'
/* eslint-disable global-require */
const data = {
  faqData: {
    contents: {
      icon: 'FileTextIcon',
      title: 'Content',
      subtitle: 'Explore the features and capabilities',
      qandA: [
        {
          question: 'Can I track my progress in the game?',
          ans: 'Yes! You have access to a personal dashboard that tracks your progress, achievements, and completed levels in real-time.',
        },
        {
          question: 'Are the surveys personalized?',
          ans: 'Surveys can be customized based on the goals of the project. Personalized surveys allow for a better assessment and tailored feedback.',
        },
        {
          question: 'Is it possible to access e-learning content offline?',
          ans: 'Currently, e-learning content is only available online. However, our team is working on a feature to allow offline access for selected courses.',
        },
        {
          question: 'How are achievements and badges awarded?',
          ans: 'Achievements and badges are awarded based on completed milestones, accuracy in surveys, and other metrics. You can view them in your profile under the "Achievements" section.',
        },
        {
          question: 'Can I create custom content or levels?',
          ans: 'As an admin user, you have the ability to create custom levels or content. You can set specific goals, upload resources, and modify settings for a tailored experience.',
        },
        {
          question: 'What if I need help or support?',
          ans: 'We have a dedicated support team available to assist you. You can reach us via chat within the platform or through our "Help" section for further guidance.',
        },
      ],
    },
    projects: {
      icon: 'BoxIcon',
      title: 'Projects',
      subtitle: 'Manage your projects seamlessly',
      qandA: [
        {
          question: 'How do I create a new project?',
          ans:
            'To create a new project, go to the "Projects" section, click on "Create New Project," and fill in the necessary details such as project name, description, start date, and team members. Once saved, your project will be accessible to your team.',
        },
        {
          question: 'Can I set specific milestones and deadlines?',
          ans:
            'Yes! You can set milestones, deadlines, and task dependencies to keep track of the project’s progress. Navigate to the project timeline view and add milestones or tasks along with their respective due dates.',
        },
        {
          question: 'How can I add team members to a project?',
          ans:
            'In the project settings, you’ll find an option to "Add Team Members." Simply select the members from your organization, assign roles, and specify permissions as needed. They will be notified and have access to the project based on the assigned role.',
        },
        {
          question: 'Is it possible to track project progress and individual contributions?',
          ans:
            'Yes, you can monitor both overall project progress and individual contributions. The dashboard provides insights into completed tasks, pending actions, and each member’s involvement, helping you ensure the project stays on track.',
        },
        {
          question: 'What tools are available for team communication and collaboration?',
          ans:
            'The platform includes an integrated messaging system, task commenting, and file sharing to facilitate seamless collaboration. You can leave comments on tasks, share files, and use the messaging feature for real-time communication.',
        },
      ],
    },
    tasksAndSubTask: {
      icon: 'ActivityIcon',
      title: 'Task & SubTask',
      subtitle: 'Explore tasks and subtasks for surveys and games',
      qandA: [
        {
          question: 'How do I create a new task for my survey?',
          ans:
            'To create a new survey task, go to the "Tasks" section, select "Create New Task," and add details such as the survey question, description, and expected responses. Each task can contain multiple subtasks if needed, making it easy to organize related questions.',
        },
        {
          question: 'Can I add subtasks to game levels?',
          ans:
            'Yes, you can add subtasks to each game level. These subtasks can represent specific actions or challenges needed to complete the level. Navigate to the level setup, select "Add Subtask," and define each step or objective for a more engaging experience.',
        },
        {
          question: 'How can I use tasks and subtasks to organize a survey?',
          ans:
            'In surveys, tasks represent individual questions, and subtasks can represent the options or follow-up questions. This setup allows you to keep track of each response and provides a structured approach to survey completion and analysis.',
        },
        {
          question: 'What kind of game elements can be set as subtasks?',
          ans:
            'Subtasks in a game context can include achievements, mini-goals, or specific checkpoints that the player must reach to proceed. These elements create a more engaging experience and ensure that each level is completed thoroughly.',
        },
        {
          question: 'How can I track progress for survey tasks?',
          ans:
            'Progress for survey tasks can be monitored in the "Survey Dashboard," where each task’s status is displayed. You can view the completion rate, time spent, and accuracy for each subtask within a survey.',
        },
        {
          question: 'Can I create recurring tasks for game content?',
          ans:
            'Yes, recurring tasks can be set up in game content, allowing players to complete daily or weekly challenges. These tasks refresh on the specified schedule and help keep the gameplay dynamic and engaging.',
        },
        {
          question: 'Are there any limits to the number of subtasks I can add?',
          ans:
            'No, there is no hard limit to the number of subtasks you can add to a task. You can organize content as you need for surveys or games, creating as many subtasks as required to achieve your objectives.',
        },
      ],
    },
    payment: {
      icon: 'CreditCardIcon',
      title: 'License & Payment',
      subtitle: 'Find the right license for your team',
      qandA: [
        {
          question: 'Which license should I choose for my team size?',
          ans:
            'Our licenses are tailored based on the number of users. For small teams (up to 10 users), we recommend the Basic License. Larger teams should consider our Professional or Enterprise licenses for added features and support.',
        },
        {
          question: 'Can I upgrade my license if my team grows?',
          ans:
            'Absolutely! You can upgrade your license at any time as your team grows. Reach out to our support team or use the "Upgrade License" option in your account settings to select a plan that suits your updated team size.',
        },
        {
          question: 'How is the cost calculated for each user?',
          ans:
            'The cost is calculated per user per month. Each license tier has a different cost per user, which decreases with larger team sizes, offering a better rate as you add more members.',
        },
        {
          question: 'Is there an annual payment option for licenses?',
          ans:
            'Yes, we offer both monthly and annual payment options. Choosing the annual option provides a discount of up to 15%, helping you save on long-term commitments.',
        },
        {
          question: 'Can I add or remove users from my license at any time?',
          ans:
            'Yes, you have the flexibility to add or remove users as needed. If you add users, the additional cost will be calculated on a prorated basis for the current billing cycle.',
        },
        {
          question: 'What payment methods do you accept?',
          ans:
            'We accept major credit cards, bank transfers, and online payment systems like PayPal. For large teams, we can also set up invoicing for an easier transaction process.',
        },
        {
          question: 'How do refunds work if I reduce the number of users?',
          ans:
            'If you reduce the number of users, your next billing cycle will automatically adjust based on the updated user count. Refunds are not issued for the current cycle, but adjustments are reflected in future invoices.',
        },
      ],
    },
    userAndCompany: {
      icon: 'LayoutIcon',
      title: 'Users & Company',
      subtitle: 'Managing your company and users',
      qandA: [
        {
          question: 'How do I create a new company profile?',
          ans:
            'To create a company profile, go to the "Company" section, select "Create New Company," and enter details such as the company name, address, and industry. Once saved, you can begin adding users to the company.',
        },
        {
          question: 'Who can add users to a company?',
          ans:
            'Only the Company Admin has the authority to add or remove users within a company. The Company Admin role is assigned during the company setup, and only they can manage team members and assign roles.',
        },
        {
          question: 'How can I add users to my company?',
          ans:
            'As a Company Admin, go to the "Users" section within your company settings. Select "Add New User," enter their details, and assign them a role. The user will receive an email with instructions to join the company.',
        },
        {
          question: 'What roles can I assign to users?',
          ans:
            'Roles can be customized based on company needs, including Admin, Manager, and Member. Each role comes with specific permissions, allowing you to control user access and responsibilities within the company.',
        },
        {
          question: 'Can I assign multiple admins to a company?',
          ans:
            'Yes, you can assign multiple admins to a company if needed. Company Admins have full access to company settings, user management, and project assignments.',
        },
        {
          question: 'How do I remove a user from my company?',
          ans:
            'As a Company Admin, navigate to the "Users" section, locate the user you want to remove, and select "Remove User." This action will revoke their access to company resources and projects.',
        },
        {
          question: 'Can users switch between multiple companies?',
          ans:
            'Yes, users can be part of multiple companies if they are invited to each one. However, they will need separate login credentials for each company and can only access one company at a time per login session.',
        },
      ],
    },
  },
}

mock.onGet('/faq/data').reply(config => {
  const { q = '' } = config.params
  const queryLowered = q.toLowerCase()

  const filteredData = {}

  Object.entries(data.faqData).forEach(entry => {
    const [categoryName, categoryObj] = entry
    // eslint-disable-next-line arrow-body-style
    const filteredQAndAOfCategory = categoryObj.qandA.filter(qAndAObj => {
      return qAndAObj.question.toLowerCase().includes(queryLowered)
    })
    if (filteredQAndAOfCategory.length) {
      filteredData[categoryName] = { ...categoryObj, qandA: filteredQAndAOfCategory }
    }
  })

  return [200, filteredData]
})
