export default [
  {
    path: '/content/teams',
    name: 'team-list',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/teams/team-list/TeamList.vue'),
    meta: {
      pageTitle: 'Content',
      breadcrumb: [
        {
          text: 'Team List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/content/teams/:id',
    name: 'team-list-id',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/teams/team-list/TeamList.vue'),
    meta: {
      pageTitle: 'Content',
      breadcrumb: [
        {
          text: 'Team List',
          active: true,
        },
      ],
    },
  },
]
