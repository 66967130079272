import Vue from 'vue'
import VueRouter from 'vue-router'

// Routes
import { canNavigate } from '@/libs/acl/routeProtection'
import { isUserLoggedIn, getHomeRouteForLoggedInUser } from '@/auth/utils'
import useJwt from '@/auth/jwt/useJwt'
import dashboard from './routes/dashboard'
import pages from './routes/pages'
import companies from './routes/companies'
import users from './routes/users'
import task from './routes/task'
import media from './routes/media'
import content from './routes/content'
import project from './routes/project'
import sets from './routes/sets'
import teams from './routes/teams'
import goals from './routes/goals'
import charity from './routes/charity'
import report from './routes/report'

Vue.use(VueRouter)

const router = new VueRouter({

  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    { path: '/', redirect: { name: 'dashboard-analytics' } },
    ...dashboard,
    ...pages,
    ...users,
    ...companies,
    ...task,
    ...media,
    ...sets,
    ...goals,
    ...teams,
    ...report,
    ...charity,
    ...content,
    ...project,
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn()

  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    if (!isLoggedIn) return next({ name: 'auth-login' })

    // If logged in => not authorized
    return next({ name: 'misc-not-authorized' })
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    const userData = useJwt.getUser()
    next(getHomeRouteForLoggedInUser(userData || null))
  }

  return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
