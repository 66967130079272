/* eslint-disable no-return-await */
/**
 * @module:Vuex Boiler Plate
 * @name:_Rohan wijesunda
 * @author:_@bisevo
 */
// eslint-disable-next-line import/extensions, import/no-unresolved
import { tokenRequest, httpBunnyUrl } from '@core/auth/interceptors/inteceptors'
// eslint-disable-next-line import/extensions, import/no-unresolved
import { SETTIME } from '@core/services/settime'
import FileSaver from 'file-saver'

/**
 * State
 */
const state = {
  isLoading: false,
  isCreating: false,
  isDeleting: false,
  allSettimes: [],
  xlsxData: '',
}
/**
 * getters
 */
const getters = {
  isLoading: state => state.isLoading,
  isCreating: state => state.isCreating,
  isDeleting: state => state.isDeleting,
  getAllSettimes: state => state.allSettimes,
  getXlsxData: state => state.xlsxData,
}
/**
 * mutations
 */
const mutations = {
  SET_IS_CREATING: (state, data) => {
    state.isCreating = data
  },
  SET_IS_LOADING: (state, data) => {
    state.isLoading = data
  },
  SET_IS_DELETING: (state, data) => {
    state.isDeleting = data
  },
  SET_ALL_SETTIMES: (state, data) => {
    state.allSettimes = data
  },
  SET_XLSX_DATA: (state, data) => {
    state.xlsxData = data
  },
}

/**
 * actions
 */
const actions = {
  /**
   * Create new Settime
   * @returns {Promise<unknown>}
   */
  async createSettime({ commit }, data) {
    commit('SET_IS_CREATING', true)
    return await new Promise((resolve, reject) => {
      tokenRequest()
        .post(SETTIME.BASE, data)
        .then(response => {
          commit('SET_IS_CREATING', false)
          if (response.data) {
            resolve(response)
          }
        })
        .catch(error => {
          commit('SET_IS_CREATING', false)
          reject(error)
        })
    })
  },
  /**
   * update Settime
   * @returns {Promise<unknown>}
   */
  async updateSettime({ commit }, data) {
    commit('SET_IS_CREATING', true)
    return await new Promise((resolve, reject) => {
      tokenRequest()
        .put(SETTIME.BY_ID(data.id), data)
        .then(response => {
          commit('SET_IS_CREATING', false)
          resolve(response)
        })
        .catch(error => {
          commit('SET_IS_CREATING', false)
          reject(error)
        })
    })
  },
  /**
   * update Settime All
   * @returns {Promise<unknown>}
   */
  async updateSettimeAll({ commit }, data) {
    commit('SET_IS_CREATING', true)
    return await new Promise((resolve, reject) => {
      tokenRequest()
        .put(SETTIME.UPDATE_ALL(data.contentId), data)
        .then(response => {
          commit('SET_IS_CREATING', false)
          resolve(response)
        })
        .catch(error => {
          commit('SET_IS_CREATING', false)
          reject(error)
        })
    })
  },
  /**
   * delete Settime
   * @returns {Promise<unknown>}
   */
  async deleteSettime({ commit }, id) {
    commit('SET_IS_DELETING', true)
    return await new Promise((resolve, reject) => {
      tokenRequest()
        .delete(SETTIME.BY_ID(id))
        .then(response => {
          commit('SET_IS_DELETING', false)
          if (response.data) {
            resolve(response)
          }
        })
        .catch(error => {
          commit('SET_IS_DELETING', false)
          reject(error)
        })
    })
  },
  /**
   * delete Settime All
   * @returns {Promise<unknown>}
   */
  async deleteSettimeAll({ commit }, id) {
    commit('SET_IS_DELETING', true)
    return await new Promise((resolve, reject) => {
      tokenRequest()
        .delete(SETTIME.DELETE_ALL(id))
        .then(response => {
          commit('SET_IS_DELETING', false)
          if (response) {
            resolve(response)
          }
        })
        .catch(error => {
          commit('SET_IS_DELETING', false)
          reject(error)
        })
    })
  },
  /**
   * get all Settime
   * @returns {Promise<unknown>}
   */
  async fetchAllSettimes({ commit }, id) {
    commit('SET_IS_LOADING', true)
    return await new Promise((resolve, reject) => {
      tokenRequest()
        .get(SETTIME.CONTENT_ID(id))
        .then(response => {
          commit('SET_IS_LOADING', false)
          commit('SET_ALL_SETTIMES', response.data.data)
          resolve(response)
        })
        .catch(error => {
          commit('SET_IS_LOADING', false)
          reject(error)
        })
    })
  },
  /**
   * get all Settime
   * @returns {Promise<unknown>}
   */
  async fetchSetReport({ commit }, file) {
    return await new Promise((resolve, reject) => {
      const reportBlob = httpBunnyUrl() + SETTIME.GET_REPORT(file)
      try {
        FileSaver.saveAs(reportBlob, file)
        commit('SET_IS_LOADING', false)
        resolve('Download successful')
      } catch (error) {
        commit('SET_IS_LOADING', false)
        reject(error)
      }
    })
  },
}
export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}
