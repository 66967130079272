import mock from '@/@fake-db/mock'
/* eslint-disable global-require */
const data = {
  // knowledge base
  gameTemplateBase: [
    {
      id: 1,
      category: 'B-B Sales',
      img: require('@/assets/images/illustration/sales.svg'),
      title: 'B-B Sales',
      desc: 'There is perhaps no better demonstration of the folly of image of our tiny world.',
    },
    {
      id: 2,
      category: 'Well-being',
      img: require('@/assets/images/illustration/marketing.svg'),
      title: 'Well-being',
      desc: 'Look again at that dot. That’s here. That’s home. That’s us. On it everyone you love.',
    },
    {
      id: 3,
      category: 'Leadership',
      img: require('@/assets/images/illustration/api.svg'),
      title: 'Leadership',
      desc: 'every hero and coward, every creator and destroyer of civilization.',
    },
    {
      id: 4,
      category: 'Retail consulting',
      img: require('@/assets/images/illustration/personalization.svg'),
      title: 'Retail consulting',
      desc: 'It has been said that astronomy is a humbling and character experience.',
    },
    {
      id: 5,
      category: 'Expert consulting',
      img: require('@/assets/images/illustration/email.svg'),
      title: 'Email Marketing',
      desc: 'There is perhaps no better demonstration of the folly of human conceits.',
    },
    {
      id: 6,
      category: 'Communication dialogue',
      img: require('@/assets/images/illustration/demand.svg'),
      title: 'Communication dialogue',
      desc: 'Competent means we will never take anything for granted.',
    },
  ],

}
/* eslint-disable global-require */
mock.onGet('/kb/data/game_template_base').reply(() => [200, data.gameTemplateBase])

