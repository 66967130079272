/* eslint-disable no-param-reassign */
import axios from 'axios'
import {
  getAuthToken,
  getRefreshToken,
  setAuthToken,
  clearTokens,
  getKey,
} from './indentity'

const TIME_OUT = 50000
// directly get the api base url from the env
const BASE_URL = process.env.VUE_APP_API_URL
const MOBILE_BASE_URL = process.env.VUE_APP_MOBILE_URL
const BUNNY_URL = process.env.VUE_APP_BUNNY_URL
// set default values for axios
axios.defaults.baseURL = BASE_URL
axios.defaults.timeout = TIME_OUT

// axios interceptors request configuration
axios.interceptors.request.use(
  config => {
    if (getAuthToken()) {
      config.headers.Authorization = `Bearer ${getAuthToken()}`
    }
    // config.headers['x-api-key'] = process.env.VUE_APP_X_KEY
    // config.headers['x-api-secret'] = process.env.VUE_APP_X_SECRET
    config.headers['Content-Type'] = 'application/json'
    return config
  },
  error => Promise.reject(error),
)
// axios interceptors response configuration
axios.interceptors.response.use(
  response => response,
  error => {
    const originalRequest = error.config
    if (error.response.status === 401) {
      axios
        .post(`${BASE_URL}${getRefreshToken}`, {
          refreshToken: getRefreshToken(),
        })
        .then(response => {
          setAuthToken(response.data.data.accessToken)
          axios.defaults.headers.common.Authorization = `Bearer ${getAuthToken()}`
          return axios(originalRequest)
        })
        .catch(() => { })
    }
    return Promise.reject(error)
  },
)
// http request for auth
export const httpAuth = () => axios
// used for AWS presigned URL
export const baseHttpRequest = () => {
  const httpRequest = axios.create({
    baseURL: BASE_URL,
    timeout: TIME_OUT,
    headers: {},
  })
  return httpRequest
}
// not in use
export const httpRequest = () => {
  // eslint-disable-next-line no-shadow
  const httpRequest = axios.create({
    baseURL: BASE_URL,
    timeout: TIME_OUT,
    headers: {
      'Content-Type': 'application/json',
      'Accept-Language': 'en-US',
      Authorization: `Bearer ${getAuthToken()}`,
    },
  })
  httpRequest.interceptors.request.use(
    config => config,
    error => Promise.reject(error),
  )
  httpRequest.interceptors.response.use(
    // eslint-disable-next-line consistent-return
    response => {
      if (response.status === 200 || response.status === 201) {
        return Promise.resolve(response)
      }
    },
    error => Promise.reject(error),
  )
  return httpRequest
}
// http request only with tokens
let axiosTokenRequest = ''

export const tokenRequest = () => {
  axiosTokenRequest = axios.create({
    baseURL: BASE_URL,
    timeout: TIME_OUT,
    headers: {
      'Content-Type': 'application/json',
      'Accept-Language': 'en-US',
      Authorization: `Bearer ${getAuthToken()}`,
    },
  })

  axiosTokenRequest.interceptors.request.use(config => config, error => Promise.reject(error))

  axiosTokenRequest.interceptors.response.use(
    response => response,
    // eslint-disable-next-line consistent-return
    error => {
      const { response } = error
      if (response.status === 401) {
        switch (response.status) {
          case 401: // refresh token error
            clearTokens()
            window.location = '/login'
            break
          case 403: // permission error
            clearTokens()
            window.location = '/login'
            break
          default:
            Promise.reject(error)
        }
      } else {
        return Promise.reject(error)
      }
    },
  )
  return axiosTokenRequest
}

// http request for api services public
export const keyRequest = () => {
  // eslint-disable-next-line no-shadow
  const httpKeyRequest = axios.create({
    baseURL: BASE_URL,
    timeout: TIME_OUT,
    headers: getKey(),
  })
  return httpKeyRequest
}

// http request for api services public
export const mobileRequest = () => {
  // eslint-disable-next-line no-shadow
  const httpKeyRequest = axios.create({
    baseURL: MOBILE_BASE_URL,
    timeout: TIME_OUT,
    headers: getKey(),
  })
  return httpKeyRequest
}

// http request for api services to download file
export const httpRequestBunny = () => {
  // eslint-disable-next-line no-shadow
  const httpRequestXlsx = axios.create({
    baseURL: BUNNY_URL,
    timeout: TIME_OUT,
    headers: {
      accept: '*/*',
      // AccessKey: '436e7704-0a88-4e66-bc35-4929271b36e3',
    },
  })
  return httpRequestXlsx
}

export const httpBunnyUrl = () => BUNNY_URL

// for file API call
export const httpUpload = () => {
  // eslint-disable-next-line no-shadow
  const httpUpload = axios.create({
    timeout: TIME_OUT,
    headers: {
      Accept: 'application/json',
      'Accept-Language': 'en-US',
      'no-Content-Type': 'true',
      Authorization: `Bearer ${getAuthToken()}`,
    },
  })
  return httpUpload
}
