/* eslint-disable camelcase */
// get Auth token//
import jwt_decode from 'jwt-decode'
// eslint-disable-next-line import/extensions, import/no-unresolved
import jwtDefaultConfig from '../jwt/jwtDefaultConfig'

const jwtConfig = { ...jwtDefaultConfig }

const { tokenKey } = jwtConfig
const { refreshTokenKey } = jwtConfig
// get auth token
export const getAuthToken = () => {
  const auth_token = localStorage.getItem(tokenKey)
  if (auth_token) {
    return auth_token
  }
  return false
}
// set auth token
export const setAuthToken = token => {
  localStorage.setItem(tokenKey, token)
}
export const getTempToken = () => {
  const temp_token = localStorage.getItem('tempToken')
  if (temp_token) {
    return temp_token
  }
  return false
}
// set Refresh token
export const setRefreshToken = token => {
  localStorage.setItem(refreshTokenKey, token)
}
// get Refresh token
export const getRefreshToken = () => {
  const refresh_token = localStorage.getItem(refreshTokenKey)
  if (refresh_token) {
    return refresh_token
  }
  return false
}

export const isRememberMe = () => {
  const remember_me = JSON.parse(localStorage.getItem('remember_me'))
  return remember_me && remember_me
}

export const getKey = () => ({
  'Api-Key': process.env.VUE_APP_X_KEY,
  'Api-Secret': process.env.VUE_APP_X_SECRET,
})

/**
 * Check if token is expired
 * @returns {boolean}
 */
export const isExpiredToken = () => {
  // get auth token
  if (getAuthToken()) {
    let now_time_stamp = ''
    // new current date
    const now = new Date()
    // add 1 hour to current time
    now_time_stamp = now.setMinutes(now.getMinutes() + 59)
    // get expiration time stamp
    const { exp } = jwt_decode(localStorage.getItem(tokenKey))
    // compare the time stamp and expiration time
    return now_time_stamp > exp * 1000
  }
  // return false if token is not available or not expired
  return false
}

export const clearTokens = () => {
  localStorage.removeItem(tokenKey)
  localStorage.removeItem(refreshTokenKey)
}
