export default [
  {
    path: '/content/goals',
    name: 'goals-list',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/goals/goals-list/GoalsList.vue'),
    meta: {
      pageTitle: 'Content',
      breadcrumb: [
        {
          text: 'Goals List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/content/goals/:id',
    name: 'goals-list-id',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/goals/goals-list/GoalsList.vue'),
    meta: {
      pageTitle: 'Content',
      breadcrumb: [
        {
          text: 'Goals List',
          active: true,
        },
      ],
    },
  },
]
