export default {
  // Endpoints
  loginEndpoint: '/login',
  registerEndpoint: '/jwt/register',
  refreshEndpoint: '/jwt/refresh-token',
  logoutEndpoint: '/jwt/logout',

  // This will be prefixed in authorization header with token
  // e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',

  // Value of this property will be used as key to store JWT token in storage
  tokenKey: 'BIST-7584927653934',
  refreshTokenKey: 'BISTR-809327937240',
  userKey: 'BISUSER-2011050219790611',
  userNameKey: 'BISUNAME-2015042419790611',
  passwodKey: 'BISMR-1972050219790611',
  contentKey: 'BISCONTENT-1982040120150424',
  rememberMeKey: 'BISRME-2024042419790611',

}
