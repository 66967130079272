export default [
  {
    path: '/dashboard/overview',
    name: 'dashboard-overview',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/dashboard/leaderboard/Analytics.vue'),
  },
  {
    path: '/dashboard/analytics',
    name: 'dashboard-analytics',
    component: () => import('@/views/dashboard/analytics/Analytics.vue'),
  },

]
