// eslint-disable-next-line import/prefer-default-export
export const USER = {
  BASE: '/users',
  BY_ID: id => `/users/${id}`,
  EMAIL_EXISTS: email => `/users/email-exists?email=${email}`,
  RESET_PASSWORD: '/users/reset-password',
  FORGOT_PASSWORD: '/users/forgot-password',
  BY_COMPANY_ID: id => `/users/company/${id}`,
  LOGOUT: '/logout',
  USER_UPLOAD: '/user/upload',
  GET_USER_ROLE: '/user-roles',
  USER_GAME_UPLOAD: '/user/game/upload',
  USER_UPLOAD_CONFIRMATION: id => `/user/upload/confirmation/${id}`,
  GET_USER_BATCH: '/userbatch',
  DELETE_USER_BATCH: id => `/userbatch/${id}`,
  DELETE_USER_UPLOAD: id => `/useruploads/${id}`,
  GET_USER_UPLOADS_BY_BATCH_ID: params => `/useruploads/pagination/${params.page}?batch_id=${params.id}${params.searchQuery}`,
  GET_GAME_UPLOADS_BY_BATCH_ID: params => `/useruploads?batch_id=${params.id}&group_by=${params.group_by}`,
  GET_USER_PERFORMED_TASKS_BY_ID: id => `/user/task/performedtasks/${id}?limit=5`,
  GET_GROUP_GAME_BY_ID: id => `/user/competition/group/${id}?limit=5`,
  ADD: '/users',
  SEND_EMAIL_USER: '/user/send/email',
  GET_USER_NOTIFICATIONS_BY_ID: id => `/usernotifications?user_id=${id}&record_status=1`,
  UPDATE_USER_NOTIFICATIONS_BY_ID: id => `/usernotifications/${id}`,
  GET_RESET_PASSWORD: id => `/user/resetpassword?id=${id}`,
}
