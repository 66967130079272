/* eslint-disable no-return-await */
/**
 * @module:Vuex Boiler Plate
 * @name:_Rohan wijesunda
 * @author:_@bisevo
 */
// eslint-disable-next-line import/extensions, import/no-unresolved
import { tokenRequest, httpUpload } from '@core/auth/interceptors/inteceptors'
// eslint-disable-next-line import/extensions, import/no-unresolved
import { MEDIA } from '@core/services/media'

/**
 * State
 */
const state = {
  isLoading: false,
  isCreating: false,
  isDeleting: false,
  isUploading: false,
  allMedias: [],
  media: '',
}
/**
 * getters
 */
const getters = {
  isLoading: state => state.isLoading,
  isCreating: state => state.isCreating,
  isDeleting: state => state.isDeleting,
  isUploading: state => state.isUploading,
  getAllMedias: state => state.allMedias,
  getMedia: state => state.media,
}
/**
 * mutations
 */
const mutations = {
  SET_IS_LOADING: (state, data) => {
    state.isLoading = data
  },
  SET_IS_CREATING: (state, data) => {
    state.isCreating = data
  },
  SET_IS_DELETING: (state, data) => {
    state.isDeleting = data
  },
  SET_IS_UPLOADING: (state, data) => {
    state.isUploading = data
  },
  SET_ALL_MEDIAS: (state, data) => {
    state.allMedias = data
  },
  SET_MEDIA: (state, data) => {
    state.media = data
  },
}
/**
 * actions
 */
const actions = {
  /**
   * Create new Media
   * @returns {Promise<unknown>}
   */
  async createMedia({ commit }, data) {
    commit('SET_IS_UPLOADING', true)
    return await new Promise((resolve, reject) => {
      const formData = new FormData()
      formData.append('companyId', data.companyId)
      formData.append('file', data.selectedFile)
      formData.append('name', data.name)
      formData.append('language', data.language)
      tokenRequest()
        .post(MEDIA.BASE, formData)
        .then(response => {
          commit('SET_IS_UPLOADING', false)
          if (response.data) {
            resolve(response.data)
          }
        })
        .catch(error => {
          commit('SET_IS_UPLOADING', false)
          reject(error)
        })
    })
  },
  /**
   * update Medias
   * @returns {Promise<unknown>}
   */
  async updateMedia({ commit }, data) {
    commit('SET_IS_CREATING', true)
    return await new Promise((resolve, reject) => {
      tokenRequest()
        .put(MEDIA.BY_ID(data.id), data)
        .then(response => {
          commit('SET_IS_CREATING', false)
          resolve(response)
        })
        .catch(error => {
          commit('SET_IS_CREATING', false)
          reject(error)
        })
    })
  },
  /**
   * delete Media
   * @returns {Promise<unknown>}
   */
  async deleteMedia({ commit }, data) {
    commit('SET_IS_DELETING', true)
    return await new Promise((resolve, reject) => {
      tokenRequest()
        .delete(MEDIA.BY_ID(data.id))
        .then(response => {
          commit('SET_IS_DELETING', false)
          if (response.data) {
            resolve(response)
          }
        })
        .catch(error => {
          commit('SET_IS_DELETING', false)
          reject(error)
        })
    })
  },
  /**
   * get Media by ID
   * @returns {Promise<unknown>}
   */
  async fetchMediaByID({ commit }, id) {
    commit('SET_IS_LOADING', true)
    return await new Promise((resolve, reject) => {
      tokenRequest()
        .get(MEDIA.BY_ID(id))
        .then(response => {
          commit('SET_IS_LOADING', false)
          commit('SET_MEDIA', response.data.data)
          resolve(response.data)
        })
        .catch(error => {
          commit('SET_IS_LOADING', false)
          reject(error)
        })
    })
  },
  /**
   * get all  Medias
   * @returns {Promise<unknown>}
   */
  async fetchAllMedias({ commit }) {
    commit('SET_IS_LOADING', true)
    return await new Promise((resolve, reject) => {
      tokenRequest()
        .get(MEDIA.BASE)
        .then(response => {
          commit('SET_IS_LOADING', false)
          commit('SET_ALL_MEDIAS', response.data.data)
          resolve(response.data)
        })
        .catch(error => {
          commit('SET_IS_LOADING', false)
          reject(error)
        })
    })
  },
}
export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}
