export default [
  {
    path: '/content/task',
    name: 'task-list',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/task/task-list/TaskList.vue'),
    meta: {
      pageTitle: 'Content',
      breadcrumb: [
        {
          text: 'Task List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/content/task/:id',
    name: 'task-list-id',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/task/task-list/TaskList.vue'),
    meta: {
      pageTitle: 'Content',
      breadcrumb: [
        {
          text: 'Task List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/content/task-configuration',
    name: 'task-configuration',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/task/task-configuration/TaskConfiguration.vue'),
    meta: {
      pageTitle: 'Content',
      breadcrumb: [
        {
          text: 'Task Configuration',
          active: true,
        },
      ],
    },
  },
  {
    path: '/content/task-configuration/:id',
    name: 'task-configuration-id',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/task/task-configuration/TaskConfiguration.vue'),
    meta: {
      pageTitle: 'Content',
      breadcrumb: [
        {
          text: 'Task Edit',
          active: true,
        },
      ],
    },
  },
  {
    path: '/content/sub-task-configuration/:id',
    name: 'sub-task-configuration-id',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/task/task-configuration/SubTaskConfiguration.vue'),
    meta: {
      pageTitle: 'Content',
      breadcrumb: [
        {
          text: 'SubTask Configuration',
          active: true,
        },
      ],
    },
  },
]
