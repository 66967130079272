// eslint-disable-next-line import/prefer-default-export
export const ONBOARDING = {
  /* public end points */
  GET_ALL_TEMPLATE_CATEGORIES: '/game/templates-category/all',
  GET_ALL_TEMPLATE: id => `/game/templates/all?category_id=${id}`,
  GET_ALL_GAME: id => `game/all/${id}`,
  GET_USER: id => `game/user/${id}`,
  GET_COMPANY: id => `game/company/${id}`,
  RESET_GAME_TARGET_KPI: id => `/game/targets-kpi/competition/${id}`,
  GET_GAME: id => `/game/${id}`,
  ADD: '/game/onboarding',
  ADD_ONBOARDING_USER: '/game/onboarding-user',
  ADD_SALES_LEADS: '/game/salesleads',
  GET_PERFORMED_TASKS: id => `/game/${id}/performedtasks?limit=10`,
  GET_GAME_SET: id => `/game/${id}/settime`,
  GET_PERFORMED_GROUP: params => `/game/${params.id}/group?from=${params.from}&to=${params.to}`,
  ADD_UNSUBSCRIBE_EMAIL: params => `/game/unsubscribe/${params.id}?email_type=${params.email_type}&email=${params.email}`,
  GET_SET_TIME_RESULT: params => `/game/${params.id}/settimeresults/${params.setId}`,
  GET_SET_TIME_RESULTS: id => `/game/${id}/settimeresults`,
  /* end of public end points */
  GET_ALL_SALES_LEADS: '/salesleads',
  GET_SALES_LEAD: id => `/salesleads/${id}`,
  SURVEY_BY_ID: params => `/game/surveys/${params.id}?${params.query}`,
  SURVEY_QUESTION_ANSWERS: '/game/surveyquestionanswers',
  GET_TARGETS_KPIS: id => `/game/targets-kpi/competition/${id}`,
}
