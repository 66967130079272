/* eslint-disable no-return-await */
/**
 * @module:Vuex Boiler Plate
 * @name:_Rohan wijesunda
 * @author:_@bisevo
 */
// eslint-disable-next-line import/extensions, import/no-unresolved
import { keyRequest, mobileRequest } from '@core/auth/interceptors/inteceptors'
// eslint-disable-next-line import/extensions, import/no-unresolved
import { AUTH } from '@core/services/auth'

/**
 * State
 */
const state = {
  isCreating: false,
  isLoading: false,
  user: '',
  otp: '',
}
/**
 * getters
 */
const getters = {
  isCreating: state => state.isCreating,
  isLoading: state => state.isLoading,
  getUser: state => state.user,
  getOtp: state => state.otp,
}
/**
 * mutations
 */
const mutations = {
  SET_IS_LOADING: (state, data) => {
    state.isLoading = data
  },
  SET_IS_CREATING: (state, data) => {
    state.isCreating = data
  },
  SET_USER: (state, data) => {
    state.user = data
  },
  SET_OTP: (state, data) => {
    state.otp = data
  },
}
/**
 * actions
 */
const actions = {

  /**
   * Login
   * @returns {Promise<unknown>}
   */
  async userLogin({ commit }, data) {
    commit('SET_IS_LOADING', true)
    return await new Promise((resolve, reject) => {
      keyRequest()
        .post(AUTH.LOGIN, data)
        .then(response => {
          commit('SET_IS_LOADING', false)
          commit('SET_USER', response.data.user)
          resolve(response)
        })
        .catch(error => {
          commit('SET_IS_LOADING', false)
          reject(error)
        })
    })
  },
  /**
   * Forgot Password
   * @returns {Promise<unknown>}
   */
  async forgotPassword({ commit }, data) {
    commit('SET_IS_CREATING', true)
    return await new Promise((resolve, reject) => {
      mobileRequest()
        .post(AUTH.FORGOT_PASSWORD, data)
        .then(response => {
          commit('SET_IS_CREATING', false)
          if (response.data) {
            resolve(response)
          }
        })
        .catch(error => {
          commit('SET_IS_CREATING', false)
          reject(error)
        })
    })
  },
  /**
   * Reset Password
   * @returns {Promise<unknown>}
   */
  async resetPassword({ commit }, data) {
    commit('SET_IS_CREATING', true)
    return await new Promise((resolve, reject) => {
      mobileRequest()
        .post(AUTH.RESET_PASSWORD, data)
        .then(response => {
          commit('SET_IS_CREATING', false)
          if (response.data) {
            resolve(response)
          }
        })
        .catch(error => {
          commit('SET_IS_CREATING', false)
          reject(error)
        })
    })
  },
  /**
   * fetch Confirm Otp
   * @returns {Promise<unknown>}
   */
  async fetchConfrimOtp({ commit }, data) {
    commit('SET_IS_LOADING', true)
    return await new Promise((resolve, reject) => {
      mobileRequest()
        .post(AUTH.VERIFY_OTP, data)
        .then(response => {
          commit('SET_IS_LOADING', false)
          commit('SET_OTP', response.data)
          resolve(response.data)
        })
        .catch(error => {
          commit('SET_IS_LOADING', false)
          reject(error)
        })
    })
  },
}
export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}
