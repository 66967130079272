import mock from '@/@fake-db/mock'
/* eslint-disable global-require */
const data = {
  profileData: {
    header: {
      avatar: require('@/assets/images/portrait/small/avatar-s-2.jpg'),
      username: 'Rohan Wijesundara',
      designation: 'Solutions Architect | NodeJS | Angular | Vue.js | .NET Core | C# | ',
      coverImg: require('@/assets/images/profile/user-uploads/timeline.jpg'),
    },
    userAbout: {
      about: 'Solutions Architect | NodeJS | Angular | Vue.js | .NET Core | C# |',
      joined: 'May 15, 2023',
      lives: 'Stockholm, Sweden',
      email: 'stefan.hallberg@bisevo.se',
      website: 'https://www.bisevo.se',
    },
    suggestedPages: [
      {
        avatar: require('@/assets/images/icons/gold.png'),
        username: 'Real Madrid',
        subtitle: 'Game 1',
        favorite: false,
      },
      {
        avatar: require('@/assets/images/icons/silver.png'),
        username: 'Liverpool',
        subtitle: 'Game 2',
        favorite: false,
      },
      {
        avatar: require('@/assets/images/icons/bronze.png'),
        username: 'Barcelona',
        subtitle: 'Game 3',
        favorite: false,
      },
      {
        avatar: require('@/assets/images/icons/place.png'),
        username: 'Manchester United',
        subtitle: 'Game 4',
        favorite: false,
      },
      {
        avatar: require('@/assets/images/icons/gold.png'),
        username: 'PSG',
        subtitle: 'Game 4',
        favorite: true,
      },
    ],
    twitterFeeds: [
      {
        imgUrl: require('@/assets/images/avatars/5-small.png'),
        title: 'Gertrude Stevens',
        id: 'tiana59 ',
        tags: '#design #fasion',
        desc: 'I love cookie chupa chups sweet tart apple pie ⭐️ chocolate bar.',
        favorite: false,
      },
      {
        imgUrl: require('@/assets/images/avatars/12-small.png'),
        title: 'Lura Jones',
        id: 'tiana59 ',
        tags: '#vuejs #code #coffeez',
        desc: 'Halvah I love powder jelly I love cheesecake cotton candy. 😍',
        favorite: true,
      },
      {
        imgUrl: require('@/assets/images/avatars/1-small.png'),
        title: 'Norman Gross',
        id: 'tiana59 ',
        tags: '#sketch #uiux #figma',
        desc: 'Candy jelly beans powder brownie biscuit. Jelly marzipan oat cake cake.',
        favorite: false,
      },
    ],
    post: [
      {
        avatar: require('@/assets/images/portrait/small/avatar-s-18.jpg'),
        username: 'Granitor montör 1 Sandviken',
        postTime: '12 May 2023 at 1:16 AM',
        postText:
          'Vi har en aktiv dialog med kunderna om bland annat service! Fyll i appen en gång per dag och reflektera i 1 minut hur dagen gick. Det kan vara svårt att få med allt varje dag men det gör inget. Peppa varandra och lycka till!',
        likes: 1240,
        youLiked: true,
        comments: 1240,
        share: 1240,
        likedUsers: [
          { avatar: require('@/assets/images/portrait/small/avatar-s-1.jpg'), username: 'Trine Lynes' },
          { avatar: require('@/assets/images/portrait/small/avatar-s-2.jpg'), username: 'Lilian Nenes' },
          { avatar: require('@/assets/images/portrait/small/avatar-s-3.jpg'), username: 'Alberto Glotzbach' },
          { avatar: require('@/assets/images/portrait/small/avatar-s-5.jpg'), username: 'George Nordic' },
          { avatar: require('@/assets/images/portrait/small/avatar-s-4.jpg'), username: 'Vinnie Mostowy' },
        ],
        likedCount: 140,
        detailedComments: [
          {
            avatar: require('@/assets/images/portrait/small/avatar-s-6.jpg'),
            username: 'Negotiation meeting',
            comment: 'Easy & smart fuzzy search🕵🏻 functionality which enables users to search quickly.',
            commentsLikes: 30,
            youLiked: false,
          },
          {
            avatar: require('@/assets/images/portrait/small/avatar-s-8.jpg'),
            username: 'Needs analysis',
            comment: 'Unlimited color🖌 options allows you to set your application color as per your branding 🏆.',
            commentsLikes: 50,
            youLiked: true,
          },
          {
            avatar: require('@/assets/images/portrait/small/avatar-s-8.jpg'),
            username: 'Strength calculation',
            comment: 'Unlimited color🖌 options allows you to set your application color as per your branding 🏆.',
            commentsLikes: 40,
            youLiked: true,
          },
          {
            avatar: require('@/assets/images/portrait/small/avatar-s-8.jpg'),
            username: 'Planning',
            comment: 'Unlimited color🖌 options allows you to set your application color as per your branding 🏅.',
            commentsLikes: 10,
            youLiked: false,
          },
          {
            avatar: require('@/assets/images/portrait/small/avatar-s-8.jpg'),
            username: 'Jobba hemifrån',
            comment: 'Unlimited color🖌 options allows you to set your application color as per your branding 🏅.',
            commentsLikes: 20,
            youLiked: false,
          },
          {
            avatar: require('@/assets/images/portrait/small/avatar-s-8.jpg'),
            username: 'Kundmöte',
            comment: 'Unlimited color🖌 options allows you to set your application color as per your branding 🏅.',
            commentsLikes: 15,
            youLiked: false,
          },
        ],
      },
    ],
    latestPhotos: [
      { img: require('@/assets/images/profile/user-uploads/user-13.jpg') },
      { img: require('@/assets/images/profile/user-uploads/user-02.jpg') },
      { img: require('@/assets/images/profile/user-uploads/user-03.jpg') },
      { img: require('@/assets/images/profile/user-uploads/user-04.jpg') },
      { img: require('@/assets/images/profile/user-uploads/user-05.jpg') },
      { img: require('@/assets/images/profile/user-uploads/user-06.jpg') },
      { img: require('@/assets/images/profile/user-uploads/user-07.jpg') },
      { img: require('@/assets/images/profile/user-uploads/user-08.jpg') },
      { img: require('@/assets/images/profile/user-uploads/user-09.jpg') },
    ],
    suggestions: [
      {
        avatar: require('@/assets/images/portrait/small/avatar-s-9.jpg'),
        name: 'Game 1',
        mutualFriend: 'starting 2023-06-11',
      },
      {
        avatar: require('@/assets/images/portrait/small/avatar-s-6.jpg'),
        name: 'Game 2',
        mutualFriend: 'starting 2023-07-11',
      },
      {
        avatar: require('@/assets/images/portrait/small/avatar-s-7.jpg'),
        name: 'Game 3',
        mutualFriend: 'starting 2023-08-11',
      },
      {
        avatar: require('@/assets/images/portrait/small/avatar-s-8.jpg'),
        name: 'Game 4',
        mutualFriend: 'starting 2023-09-11',
      },
      {
        avatar: require('@/assets/images/portrait/small/avatar-s-1.jpg'),
        name: 'Game 5',
        mutualFriend: 'starting 2023-10-11',
      },
      {
        avatar: require('@/assets/images/portrait/small/avatar-s-10.jpg'),
        name: 'Game 6',
        mutualFriend: 'starting 2023-11-11',
      },
    ],
    polls: [
      {
        name: 'RDJ',
        result: '82%',
        votedUser: [
          { img: require('@/assets/images/portrait/small/avatar-s-12.jpg'), username: 'Tonia Seabold' },
          { img: require('@/assets/images/portrait/small/avatar-s-5.jpg'), username: 'Carissa Dolle' },
          { img: require('@/assets/images/portrait/small/avatar-s-9.jpg'), username: 'Kelle Herrick' },
          { img: require('@/assets/images/portrait/small/avatar-s-10.jpg'), username: 'Len Bregantini' },
          { img: require('@/assets/images/portrait/small/avatar-s-11.jpg'), username: 'John Doe' },
        ],
      },
      {
        name: 'Chris Hemswort',
        result: '67%',
        votedUser: [
          { img: require('@/assets/images/portrait/small/avatar-s-9.jpg'), username: 'Tonia Seabold' },
          { img: require('@/assets/images/portrait/small/avatar-s-1.jpg'), username: 'Carissa Dolle' },
          { img: require('@/assets/images/portrait/small/avatar-s-8.jpg'), username: 'Jonathan Lyons' },
        ],
      },
    ],
  },
}
/* eslint-disable global-require */
mock.onGet('/profile/data').reply(() => [200, data.profileData])
