// eslint-disable-next-line import/prefer-default-export
export const TASK = {
  BASE: '/tasks',
  BY_ID: id => `/tasks/${id}`,
  CONTENT_ID: id => `/tasks/content/${id}`,
  TYPE: '/task-types',
  SUBTASK_TYPE: '/subtask-types',
  FREQUENCY: '/task-frequencies',
  BASE_SUBTASK: '/subtasks',
  SUBTASK_BY_ID: id => `/subtasks/${id}`,
}
export const SUBTASK = {
  BASE: '/subtasks',
  BY_ID: id => `/subtasks/${id}`,
  TYPE: '/subtask-types',
}
