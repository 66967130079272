export default [
  {
    path: '/content/sets',
    name: 'set-list',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/sets/sets-list/SetList.vue'),
    meta: {
      pageTitle: 'Content',
      breadcrumb: [
        {
          text: 'Set List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/content/sets/:id',
    name: 'set-list-id',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/sets/sets-list/SetList.vue'),
    meta: {
      pageTitle: 'Content',
      breadcrumb: [
        {
          text: 'Set List',
          active: true,
        },
      ],
    },
  },
]
