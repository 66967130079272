// eslint-disable-next-line import/prefer-default-export
export const CONTENT = {

  BASE: '/contents',
  BY_ID: id => `/contents/${id}`,
  CONTENT_FREQUENCIES: 'content-frequencies',
  CONTENT_STATUSES: 'contentstatuses',
  CONTENT_TYPES: 'content-types',
  INVITATIONS: id => `/contents/${id}/send-invitation-to-all-users`,
  BY_COMPANY_ID: id => `/contents/company/${id}`,
  BY_PROJECT_ID: id => `/contents/project/${id}`,
  QUESTION: '/questions',
  SURVEY: '/surveys',
  SURVEY_QUESTION: '/surveyquestions',
  QUESTION_ANSWER_BY_ID: id => `/questionanswers/${id}`,
  SURVEY_USER: '/surveyusers',
  QUESTION_BY_ID: id => `/questions/${id}`,
  GET_QUESTION_BY_MEDIA_ID: id => `/questions?mediaID=${id}`,
  GET_ALL_QUESTIONS: params => `/questions/pagination${params}`,
  GET_SURVEY_ALL: page => `/surveys/pagination/${page}`,
  SURVEY_BY_ID: id => `/surveys/${id}`,
  SURVEY_RESULT_BY_ID: id => `/surveys/results/${id}`,
  SURVEY_SET_BY_ID: params => `/surveysets/${params}`,
  SURVEY_QUESTION_BY_ID: id => `/surveyquestions/${id}`,
  SURVEY_USER_BY_ID: id => `/surveyusers/${id}`,
  SURVEY_ANALYZE_BY_ID: id => `/surveys/analyze/${id}`,
  ALL_PROJECT_USERS_SURVEY: params => `/surveys/${params.id}/projects/${params.project_id}/add-all-users`,
}
